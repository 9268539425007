// export const APP_ID = '228147d3fbc6cdb4';
// export const APP_ID = '20889759074dd9cd';
export const APP_ID = localStorage.getItem("CC_APP_ID") ?? '23965108c9b89ad2';
export const REGION = 'us';
// export const AUTH_KEY = '65fdf74d80a3ac10e03c2edc8f7a85af14757a65';
// export const AUTH_KEY = '7940ec021015805ed0741f5f53a69b377515cac7';
export const AUTH_KEY = localStorage.getItem("CC_AUTH_KEY") ?? 'd856a2e1e2fe4794f0b6efa14c78a39a873075c2';
localStorage.setItem("CC_APP_ID", APP_ID);
localStorage.setItem("CC_AUTH_KEY", AUTH_KEY);
export const WEB_APP_HOME = 'https://cometchat.pages.dev/';
export const WEB_APP_DYNAMIC_LINK = 'https://cometchat.page.link/?link=https://cometchat.pages.dev/__ID__&apn=com.reactnativecallingandroid';
export const WIDGET_ID = '70470a88-7f9e-4786-9dec-af41c3e6de11';
